import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-tenant36',
  templateUrl: './card-tenant36.component.html',
  styleUrls: ['./card-tenant36.component.scss']
})
export class CardTenant36Component implements OnInit {

  showText = false;
  alertMessage = '';
  counter = 0;
  cardValue;
  card_rem_digit;
  card_num_show = 'xxxx xxxx xxxx xxxx';
  exp = 'xx/xx';
  cvv = 'xxx';
  // public isVisible: boolean = false;
  // public isVisible: boolean = false;
  public card_num: boolean = false;
  public expiry: boolean = false;
  public card_cvv:boolean = false;
 
  // card_num: boolean;
  // expiry: boolean;
  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles;

  constructor() { }


  ngOnInit() {
  }

    // new alaanpay card ts
    copyToClipboard(item,value): void {
    this.card_num = false;
    this.expiry = false;
    this.card_cvv = false;
    if(value === 'card_num'){
      this.card_num = true;
    }else if(value === 'expiry'){
      this.expiry = true;
    }else{
      this.card_cvv = true;
    }   
        // const selBox = document.createElement('textarea');
        // selBox.style.position = 'fixed';
        // selBox.style.left = '0';
        // selBox.style.top = '0';
        // selBox.style.opacity = '0';
        // selBox.value = item;
        // document.body.appendChild(selBox);
        // selBox.focus();
        // selBox.select();
        item.select();
        document.execCommand('copy');
        item.setSelectionRange(0, 0);
        navigator.clipboard.writeText(item.value.replace(/\s/g, ''));
        setTimeout(()=>{
            this.card_num = false;
            this.expiry = false;
            this.card_cvv= false;
          }, 1000);
    }

}
