import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-card-details-hugo',
  templateUrl: './card-details-hugo.component.html',
  styleUrls: ['./card-details-hugo.component.scss']
})
export class CardDetailsHugoComponent implements OnInit {

  showText = true;
  card_num_show = '**** **** **** ****';
  exp = '**/**';
  cvv = '***';
  

  public card_num: boolean = false;

  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles;
  @Input() card_number;

  constructor() { }

  toggleShow(){
    this.showText = !this.showText;
    this.toggleNumber();
    this.toggleExpiry();
    this.toggleCvv();
  }

  toggleNumber(){
    this.card_num_show = '';
    if(!this.showText){
      let number = this.card_detail.card_number.split(' ');
      number.forEach((num,index) => {
        if(index !== number.length - 1){
          this.card_num_show += num.replace(num,'**** ');
        }else{
          this.card_num_show += num;
        }
      });
    }else{
      this.card_num_show = this.card_detail.card_number;
    }
  }

  toggleExpiry(){
    this.exp = '';
    this.exp = this.card_detail.exp;
    if(!this.showText){
      let expiry = this.card_detail.exp.split('/');
      this.exp = expiry[0].replace(expiry[0],'**')+'/'+expiry[1].replace(expiry[1],'**');
    }else{
      this.exp = this.card_detail.exp;
    }
  }

  toggleCvv(){
    this.cvv = '';
    this.cvv = this.card_detail.cvv;
    if (!this.showText && Object.keys(this.card_detail).length > 0) {
      this.cvv = this.card_detail.cvv.replace(
        this.card_detail.cvv,
        "***"
      );
    } else {
      this.cvv = this.card_detail.cvv;
    }
  }

  copyToClipboard(item): void {
    this.card_num = true;
    item.select();
    document.execCommand("copy");
    item.setSelectionRange(0, 0);
    navigator.clipboard.writeText(item.value.replace(/\s/g, ""));
    setTimeout(() => {
      this.card_num = false;
    }, 1000);
  }


  ngOnInit() {
    this.toggleShow();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes){
      if(this.card_detail.card_number != undefined){
        this.toggleNumber();
        this.toggleExpiry();
        this.toggleCvv();
      }
    }
  }

}